<template>
  <div>
    <h1>Purchase Failed</h1>
    <p> An error has occured and your transaction has been cancelled - please contact an administrator. </p>
    <p> <strong> Transaction Id: </strong> {{ transactionId }} </p>
  </div>
</template>

<script>
export default {
  name: 'public-company-create-error',
  data: function () {
    return {
      transactionId: null
    }
  },
  mounted () {
    this.transactionId = this.$route.query.transId;
  }
}
</script>
